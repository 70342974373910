String.prototype.htmlspecialchars = function () {
	var map = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		"'": '&#039;'
	};

	return this.replace(/[&<>"']/g, function(m) { return map[m]; });
};

jQuery.expr[':'].focus = function( elem ) {
	return elem === document.activeElement && ( elem.type || elem.href );
};

var remodal_instance = null;

$(document).ready(function () {
	// Make sure no ajax requests are cached
	$.ajaxSetup({ cache: false });

	$('.message-box-close').click(function () {
		$(this).closest('div').hide();
	});

	$('.megamenu-link').hover(
		function() {
			//hover
			var elem = $(this).find('.hidden_megamenu_html');
			if (elem.length > 0) {
				elem.css('left', $('#category-nav li:first').position().left).show();
			}
		},
		function() {
			//un-hover
			var elem = $(this).find('.hidden_megamenu_html');
			if (elem.length > 0) {
				elem.hide();
			}
		}
	);

	// Modal instance that is used everywhere
	remodal_instance = $('#remodal-dialog').remodal({hashTracking: false});

	if (navigator.userAgent.indexOf('MSIE') == -1) {
		$('#main-search-box').smartSuggest({
			src: '/search.ajax?mode=autocomplete',
			showImages: true,
			fillBox: true,
			showEmptyCategories: false
		});
	}

	$('#top-scroller').click(function() {
		$("html, body").animate({ scrollTop: 0 }, "slow");
		return false;
	});

	// TODO re-enable and improve
	// Show "Back to top" button whilst scrolling up
	//var current_position = 0;
	//$(window).scroll(function() {
	//	var scrolling_position = $(window).scrollTop();
	//	var scroll_to_top_button = $('#top-scroller');
	//
	//	if(scrolling_position > current_position) { // Downwards
	//		if (scroll_to_top_button.is(':visible')) {
	//			scroll_to_top_button.fadeOut();
	//		}
	//	} else { // Upwards
	//		if ($(this).scrollTop() > 300) {
	//			scroll_to_top_button.fadeIn();
	//		} else {
	//			scroll_to_top_button.fadeOut();
	//		}
	//	}
	//
	//	// Update position
	//	current_position = scrolling_position;
	//});

	setup_dialog();

	$('body').on('click', '._accordion-choice h3', function() {
		$(this).siblings('._choice-content').toggle();
	});

	// Adding a product to the basket
	$(document).on('submit', '.add_product_to_basket', function(e) {
		e.preventDefault();
		e.stopPropagation();

		// Block either the form that initiated the product add, or the wrapper that contains the form in the case of a product swap
		if ($(this).hasClass('_product-swap-form')) {
			var section_to_block = $('#product-swap-box');
		} else if ($(this).hasClass('_order_product_regularly')) {
			var section_to_block = $('#order_product_regularly');
		} else {
			var section_to_block = $(this);
		}

		block_element(section_to_block, i18next.t('adding_w_ellipsis'));

		var qty = $('input.add_product_basket_quantity', this).val();
		var swap_qty = $(this).data('attr_swap_qty');
		
		if ($(this).hasClass('_product_swap_available') && qty % swap_qty === 0) { // Is a product swap available?
			$(this).removeClass('_product_swap_available'); // Only offer once per page load, if cancelled or choose no etc... don't offer it again
			$(this).removeClass('_products_ordered_regularly'); // we don't want to bombard the user with popups, so don't offer regular delivery if they've already ignored the swap and save
			$.ajax({
				type: "GET",
				url: "/family.ajax",
				data: $(this).serialize() + '&status=add_to_basket&mode=products_swap',
				success: function (response) {

					var modal_content = $('#remodal-dialog-content');
					modal_content.html('');
					if (response.status == 'OK') {
						if (response.data !== undefined) {
							modal_content.html(response.data.html);
						} else {
							modal_content.html(response.message);
						}
						remodal_instance.open();

					} else {
						console.log(response);
						modal_content.html(
							'<p>Error: ' + response.message + '</p>' +
							'<div class="grid-box _one-whole _centered _no-padding">' +
							'<a class="cta-button _cta-primary _one-half" href="">' + i18next.t('refresh_page') + '</a>' +
							'</div>'
						);
						remodal_instance.open();
					}
					unblock_element(section_to_block);
				}
			});
		} else if ($(this).hasClass('_products_ordered_regularly')) { // Does the user order this product regularly?
			$(this).removeClass('_products_ordered_regularly'); // Only offer once per page load, if cancelled or choose no etc... don't offer it again
			$.ajax({
				type: "GET",
				url: "/family.ajax",
				data: $(this).serialize() + '&status=add_to_basket&mode=orders_regularly_popup',
				success: function (response) {
					var modal_content = $('#remodal-dialog-content');
					modal_content.html('');
					if (response.status == 'OK') {
						if (response.data !== undefined) {
							notification_dialog(response.data.html, response.data.title, '', '', '');

							function check_timeline_buttons() {
								if ($('.horizontal-timeline').length > 0) {
									var container_width = $('.horizontal-timeline').outerWidth();
									var timeline_width = $('.horizontal-timeline .events').outerWidth();
									var initial_offset = container_width - timeline_width;
									if (initial_offset >= 0) {
										$('.horizontal-timeline .events').css('left', '0px');
										$('.remodal .timeline-next').addClass('disabled');
										$('.remodal .timeline-prev').addClass('disabled');
									} else {
										$('.horizontal-timeline .events').css('left', initial_offset + 'px');
										$('.remodal .timeline-next').addClass('disabled');
										$('.remodal .timeline-prev').removeClass('disabled');
									}
								}
							}

							$(window).on('resize', function () {
								check_timeline_buttons();
							});
							check_timeline_buttons();

							$('.remodal .timeline-next').addClass('disabled');
							// prev
							$('.remodal').on('click', '.timeline-prev', function () {
								var container_width = $('.horizontal-timeline').outerWidth();
								var timeline_width = $('.horizontal-timeline .events').outerWidth();
								var current_slide = $('.horizontal-timeline .events').position().left;

								if (timeline_width <= (container_width + 100)) {
									return;
								}

								var width_to_slide = current_slide + (container_width / 2); // divide container width by 2, that way we'll slide 1/2 a container at a time
								if (width_to_slide > -100) {
									width_to_slide = 0;
									$(this).addClass('disabled');
									$('.remodal .timeline-next').removeClass('disabled');
								}

								$('.remodal .timeline-next').removeClass('disabled');
								$('.horizontal-timeline .events').animate({'left': width_to_slide}, 1200);
							});

							// next
							$('.remodal').on('click', '.timeline-next', function () {
								var container_width = $('.horizontal-timeline').outerWidth();
								var timeline_width = $('.horizontal-timeline .events').outerWidth();
								var current_slide = $('.horizontal-timeline .events').position().left;

								if (timeline_width <= (container_width + 100)) {
									return;
								}
								var max_slide = container_width - timeline_width;

								var width_to_slide = current_slide - (container_width / 2);
								if ((width_to_slide - 100) < (max_slide)) {
									width_to_slide = max_slide;
									$(this).addClass('disabled');
									$('.remodal .timeline-prev').removeClass('disabled');
								}
								$('.remodal .timeline-prev').removeClass('disabled');
								$('.horizontal-timeline .events').animate({'left': width_to_slide}, 1200);
							});

							$(document).on('closing', '#remodal-dialog', function(e) {
								if ($('#remodal-dialog').find('form._order_product_regularly').length > 0) {
									$('#remodal-dialog').find('form._order_product_regularly').submit();
								}
							});
						} else {
							modal_content.html(response.message);
							remodal_instance.open();
						}
					} else {
						modal_content.html(
							'<p>Error: ' + response.message + '</p>' +
							'<div class="grid-box _one-whole _centered _no-padding">' +
							'<a class="cta-button _cta-primary _one-half" href="">' + i18next.t('refresh_page') + '</a>' +
							'</div>'
						);
						remodal_instance.open();
					}
					unblock_element(section_to_block);
				}
			});
		} else if ($(this).hasClass('_products_warn_about_tablet_pack_size')) { // Does the user think they have bought a pack instead of a tablet?
			$(this).removeClass('_products_warn_about_tablet_pack_size'); // Only offer once per page load
			if($(this).find('input[name="quantity"]').val() == 1) {
				$.ajax({
					type: "GET",
					url: "/family.ajax",
					data: $(this).serialize() + '&status=add_to_basket&mode=warn_about_tablet_pack_size',
					success: function (response) {
						var modal_content = $('#remodal-dialog-content');
						modal_content.html('');
						if (response.status == 'OK') {
							if (response.data !== undefined) {
								notification_dialog(response.data.html, response.data.title, '', '', '');
							}
						}
					}
				});
			}
			unblock_element(section_to_block);

		} else {
			// track event for facebook
			try {
				fbqtrack_add_to_cart(
					$(this).find('input[name="products_id"]').val(), // product id
					$(this).attr('data-attr_price'), // price
					$(this).attr('data-attr_currency'), // currency
					$(this).attr('data-attr_title')// name
				);
			} catch (e) {
				// error occurs if function doesn't exist, don't need to handle that
			}

			$.ajax({
				type: "GET",
				url: "/dialogs.ajax",
				data: $(this).serialize(),
				success: function (response) {
					var modal_content = $('#remodal-dialog-content');
					modal_content.html('');

					if (response.status == 'OK') {
						if (response.data !== undefined) {
							modal_content.html(response.data.html);
							remodal_instance.open();

							// Update the basket with new values
							// Large basket wrapper
							//$('.basket-items-quantity').text(response.data.basket.header_basket_items_with_applicable_suffix);
							$('.basket-items-quantity').text(response.data.basket.total_basket_items_count_with_suffix);
							$('.basket-value').text('- ' + response.data.basket.header_basket_value);
							pulse_element('.basket-wrapper');

							// Small basket wrapper
							$('.basket-items-quantity._sm').text(response.data.basket.total_basket_items_count);
							$('.basket-value._sm').text(response.data.basket.header_basket_value);

						} else {
							modal_content.html(response.message);
							remodal_instance.open();
						}
					} else {
						modal_content.html(
							'<p>' + i18next.t('error') + ': ' + response.message + '</p>' +
							'<div class="grid-box _one-whole _centered _no-padding">' +
							'<a class="cta-button _cta-primary _one-half" href="">' + i18next.t('refresh_page') + '</a>' +
							'</div>'
						);
						remodal_instance.open();
					}
					unblock_element(section_to_block);
				}
			});
		}
	});

	$(document).on('click', '.basket_banner_add_product', function(event) {
		var button_wrapper = $(this).parent();
		var products_id = $(this).data('products_id');

		block_element('.banner-product-details');
		$.ajax({
			url: '/shopping_basket.ajax?mode=add_basket_banner_product&products_id=' + products_id + '&quantity=1',
			dataType: 'json',
			success: function (response) {
				if (response.status == 'OK') {
					button_wrapper.html('<button class="btn-round btn-success-green btn-round-smaller">ADDED&nbsp;<i class="far fa-check fa-fw"></i></button>');
				}
				unblock_element('.banner-product-details');
			}
		});
	});

	$(document).on('click', '._close-remodal', function() {
		remodal_instance.close();
	});

	/* Load generic swiper elements */
	if ($('.swiper-generic')[0] && $('.swiper-generic .swiper-slide').length > 0) {
		var autoplay = {delay:7000};
		if($('.swiper-generic .swiper-slide').length == 1){
			autoplay = false;
		}
		new Swiper('.swiper-generic', {
			speed: 500,
			autoplay: autoplay,
			loop: false,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			// Disable preloading of all images
			preloadImages: false,
			// Enable lazy loading
			lazy: true
		});
	}

	$(document).on('change', '._animal-species-change', function() {
		var gender_select_box = $(this).data('linked-to');
		update_gender_selectbox_terminology($(gender_select_box), $(this).val());
	});

	/* subscribe to newsletter button in footer */
	if (location.search.substr(1).indexOf('open_newsletter_modal=1') != -1) {
		trigger_newsletter_popup();
	}
	$('#newsletter_signup').on('click', function () {
		trigger_newsletter_popup();
	});
	$('.newsletter_form').on('submit', function (e) {
		e.preventDefault();
	});

	$('#nps-form :radio[name="experience"]').change(function() {
		$('#nps-form').submit();
	});

	$('#nps-form').submit(function(event) {
		event.preventDefault();
		event.stopPropagation();

		$.post('process_nps.ajax', $('#nps-form').serialize());

		if ($('#nps-comments-wrapper').is(":visible")) {
			$('#nps-form').hide();
			$('#nps-thanks').show();
		} else {
			$('#nps-experience-wrapper').hide();
			$('#nps-comments-wrapper').show();
		}
	});
});

function pulse_element(selector) {
	$(selector).fadeOut('fast').fadeIn('fast');
}

function block_element(selector, message) {
	// Default message
	message = typeof message !== 'undefined' ? message : i18next.t('updating_w_ellipsis');

	var element = $(selector);
	if (element.children('.block-element').length === 0) {
		element.addClass('_element-blocked');
		element.append('<div class="block-element"><i class="far fa-cog fa-2x fa-spin"></i><br>' + message + ' </div>');
	}
}

function unblock_element(selector) {
	var element = $(selector);
	element.removeClass('_element-blocked');
	element.children('.block-element').remove();
}

function deal_with_generic_ajax_response(response) {
	// Error message?
	if (response.status != 'OK') {
		notification_dialog(response.message, i18next.t('error_occurred'));
	}

	// Do we have data to use?
	if (response.data) {
		// Do we need to force a page reload?
		if (response.data.force_page_reload) {
			location.reload();
			return false;
		}

		// How about a callback function?
		if (response.data.callback) {
			var cb = response.data.callback;
			window[cb](response);
		}
	}

	return true;
}

function setup_dialog() {
	$(document).on('click', '.dialog-box', function(e) {
		e.preventDefault();
		e.stopPropagation();

		var dialog_mode = $(this).data('dialog_mode');
		var dialog_location = $(this).data('dialog_location');
		var dialog_event_name = $(this).data('dialog_event_name');
		var dialog_event_target = $(this).data('dialog_event_target');
		var dialog_url = $(this).data('dialog_url');

		var params = {};
		if ($(this).data('params')) {
			params = $(this).data('params');
		}

		params.mode = dialog_mode;
		params.url = dialog_url;
		$.ajax({
			type: "GET",
			url: "/" + dialog_location + ".ajax",
			data: params,
			success: function (response) {
				var modal_content = $('#remodal-dialog-content');
				modal_content.html('');
				if (response.status == 'OK') {
					modal_content.html(response.data.html);
					remodal_instance.open();

					// trigger event once completed
					if (dialog_event_name != '') { // TODO needed?
						$(dialog_event_target).trigger(dialog_event_name);
					}
				} else {
					modal_content.html(
						'<p>Unable to load content, please refresh the page and try again.</p>' +
						'<div class="grid-box _one-whole _centered _no-padding">' +
						'<a class="cta-button _cta-primary _one-half" href="">' + i18next.t('refresh_page') + '</a>' +
						'</div>'
					);
					remodal_instance.open();
				}
			}
		});
	});
}

function notification_dialog(content, title, okay_button_text, okay_button_action, cancel_button_text, max_width_override) {
	max_width_override = typeof max_width_override !== 'undefined' ? max_width_override : null;
	title = typeof title !== 'undefined' ? title : '';
	okay_button_text = typeof okay_button_text !== 'undefined' ? okay_button_text : i18next.t('ok');
	okay_button_action = typeof okay_button_action !== 'undefined' ? okay_button_action : null;
	cancel_button_text = typeof cancel_button_text !== 'undefined' ? cancel_button_text : '';

	var modal_container = $('#remodal-dialog');
	var modal_content = $('#remodal-dialog-content');
	modal_content.html('');
	var notification_html = '';

	if (title !== '') {
		notification_html += '<div class="grid-box _one-whole _no-padding _no-margin"><h2 class="_no-margin-top">' + title + '</h2></div>';
	}

	var okay_button_on_click = '';
	if (okay_button_action !== null) {
		okay_button_on_click = okay_button_action;
	}

	notification_html += '<div class="grid-box _one-whole _no-padding">' + content + '</div><div class="grid-box _one-whole _no-padding _no-margin _centered">';

	if (okay_button_text != '') {
		notification_html += '<button data-remodal-action="confirm" class="cta-button _cta-primary remodal-confirm _no-margin" onclick="' + okay_button_on_click + '">' + okay_button_text + '</button>';
	}

	if (cancel_button_text !== '') {
		notification_html += '<button data-remodal-action="cancel" class="cta-button remodal-cancel _no-margin" style="margin-left:5px;">' + cancel_button_text + '</button>';
	}

	notification_html += '</div>';

	if (max_width_override != null) {
		$(modal_container).css('max-width', max_width_override);
	} else {
		$(modal_container).css('max-width', '');
	}

	modal_content.html(notification_html);
	remodal_instance.open();
}

function scrollToObject(obj) {
	$(document.body).scrollTop($(obj).offset().top);
}

// GA banner tracking
function log_banner_impression(category, label) {
	vio.track.event(category, 'Impression', label, {'nonInteraction': 1});
}

// Listen to banner click events and report them to Google Analytics (if this page has GA on it)
$('body').on('click', '.track_banner_clicks', function () {
	// Default to an event type of click, unless it's been specified
	var event_type = 'Click';
	if (typeof $(this).data('banner_event') != 'undefined') {
		event_type = $(this).data('banner_event');
	}

	vio.track.event($(this).data('banner_category'), event_type, $(this).data('banner_label'), {'nonInteraction': 1});
});

// Specific gender types
if (typeof specific_animal_genders == 'undefined') {
	var specific_animal_genders = [];
}
function get_specific_animal_gender(species_id, terminology) {
	// If specific terminology for this gender has been specified, use that
	if (typeof specific_animal_genders[species_id] != 'undefined' && typeof specific_animal_genders[species_id][terminology] != 'undefined') {
		return specific_animal_genders[species_id][terminology];
	}

	// otherwise just use what's currently being used
	return terminology;
}

// Updates terminology used in a gender selectbox, pass in the select box you want to update with the currently chosen species
function update_gender_selectbox_terminology(selectbox, species_id) {
	selectbox.find('option').each(function() {
		if ($(this).val() != '') {
			var new_v = get_specific_animal_gender(species_id, $(this).val());
			$(this).text(new_v);
		}
	});
}

function in_array(needle, haystack, argStrict) {
	var key = '',
		strict = !!argStrict;

	if (strict) {
		for (key in haystack) {
			if (haystack[key] === needle) {
				return true;
			}
		}
	} else {
		for (key in haystack) {
			if (haystack[key] == needle) {
				return true;
			}
		}
	}

	return false;
}

function set_max_pet_dob_year() {
	var current_year = new Date().getFullYear();
	$('.picker__select--year option').each(function() {
		if ($(this).val() > current_year) {
			$(this).hide();
		}
	});
}
function process_newsletter_bounce_signup(){
	var container = $('#newsletter_bounce_wrapper');
	var newsName = $('.newsletter_bounce_newsletter_name').val();
	var newsEmail = $('.newsletter_bounce_newsletter_email').val();

	var animals = {};
	animals.dogs = $('#news_bounce_cb_dogs').is(':checked');
	animals.cats = $('#news_bounce_cb_cats').is(':checked');
	animals.horses = $('#news_bounce_cb_horses').is(':checked');
	animals.fish = $('#news_bounce_cb_fish').is(':checked');
	animals.birds = $('#news_bounce_cb_birds').is(':checked');
	animals.reptiles = $('#news_bounce_cb_reptiles').is(':checked');
	animals.small_animals = $('#news_bounce_cb_small_animals').is(':checked');
	animals.farm_animals = $('#news_bounce_cb_farm_animals').is(':checked');

	$.ajax({
		method: 'POST',
		url: '/newsletter_signup.ajax',
		data: {
			mode: 'add_subscriber_bounce',
			subscriber_name: newsName,
			subscriber_email: newsEmail,
			animals: animals
		},
		success: function (response) {
			if (response.status == 'OK') {
				//success
				$('.newsletter_bounce_error_message').html('');
				$('.newsletter_bounce_success_message').html(response.message);
				//hide buttons
				$('#newletter_bounce_form').hide();
				//show close
				$('.newsletter_bounce_close').show(600);
			} else {
				// error
				$('.newsletter_bounce_error_message').html(response.message);
				$('.newsletter_bounce_success_message').html('');
			}
		},
		error: function () {
			$('.newsletter_bounce_success_message').html('');
			$('.newsletter_bounce_error_message').html("Oops, something has gone wrong. Try again!");
		}
	});
}
function process_newsletter_signup() {
	var container = $('#remodal-dialog-content');
	var newsName = $(container).find('.newsletter_name').val();
	var newsEmail = $(container).find('.newsletter_email').val();
	var generic_error = $(container).find('.generic_error').text();

	var animals = {};
	animals.dogs = $(container).find('#news_animal_cb_dogs').is(':checked');
	animals.cats = $(container).find('#news_animal_cb_cats').is(':checked');
	animals.horses = $(container).find('#news_animal_cb_horses').is(':checked');
	animals.fish = $(container).find('#news_animal_cb_fish').is(':checked');
	animals.birds = $(container).find('#news_animal_cb_birds').is(':checked');
	animals.reptiles = $(container).find('#news_animal_cb_reptiles').is(':checked');
	animals.small_animals = $(container).find('#news_animal_cb_small_animals').is(':checked');
	animals.farm_animals = $(container).find('#news_animal_cb_farm_animals').is(':checked');

	$.ajax({
		method: 'POST',
		url: '/newsletter_signup.ajax',
		data: {
			mode: 'add_subscriber',
			subscriber_name: newsName,
			subscriber_email: newsEmail,
			animals: animals
		},
		success: function (response) {
			if (response.status == 'OK') {
				//success
				$(container).find('.newsletter_signup_error_message').html('');
				$(container).find('.newsletter_signup_success_message').html(response.message);
				//hide buttons
				$(container).find('button').hide();
				//show close
				$(container).find('.newsletter_popup_close').show(600);
			} else {
				// error
				$(container).find('.newsletter_signup_error_message').html(response.message);
				$(container).find('.newsletter_signup_success_message').html('');
			}
		},
		error: function () {
			$(container).find('.newsletter_signup_success_message').html('');
			$(container).find('.newsletter_signup_error_message').html(generic_error);
		}
	});
}

function process_footer_newsletter_signup() {
	var form = $('#newsletter-footer');
	$('.alert', form).remove();
	$.ajax({
		method: 'POST',
		url: '/newsletter_signup.ajax?mode=footer',
		data: form.serializeArray(),
		success: function (response) {
			if (response.status == 'OK') {
				form.replaceWith($('<div class="alert alert-success grid-box _one-whole"></div>').text(response.message));
			} else {
				// error
				form.append($('<div class="alert alert-danger grid-box _one-whole"></div>').text(response.message));
			}
		},
		error: function () {
			form.append($('<div class="alert alert-danger grid-box _one-whole">An error has occurred - please try again.</div>'));
		}
	});
}

function trigger_newsletter_popup() {
	$.ajax({
		method: 'POST',
		url: '/newsletter_signup.ajax',
		data: {mode: 'get_popup'},
		success: function (response) {
			notification_dialog(response.data.html, '', '', '', '', '380px');
		}
	});
}

function validateEmail(email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}


function show_bounce_modal(){
	var params = {};
	$.ajax({
		type: "GET",
		url: "/dialogs.ajax?mode=show_bounce_modal",
		data: params,
		success: function (response) {
			var modal_content = $('#remodal-dialog-content');
			notification_dialog(response.data.html, '', '', '', '', '600px');
		}
	});
}